import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import { ChevronDown } from '@/assets/icons/chevron-down';
import { ChevronUp } from '@/assets/icons/chevron-up';
interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}
export const DashboardSidebarItem: FC<React.PropsWithChildren<DashboardSidebarItemProps>> = props => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(!!openProp);
  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };
  let paddingLeft = 24;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return <ListItem disableGutters sx={{
      display: 'block',
      mb: 0.5,
      py: 0,
      px: 1
    }} {...other}>
        <Button endIcon={!open ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />} disableRipple onClick={handleToggle} startIcon={icon} sx={{
        fontWeight: 500,
        fontFamily: active ? 'proxima-nova-bold' : 'proxima-nova-regular',
        color: active ? 'neutral.100' : 'neutral.300',
        justifyContent: 'flex-start',
        pl: `${paddingLeft}px`,
        pr: 3,
        textAlign: 'left',
        textTransform: 'none',
        backgroundColor: active ? 'primary.main' : 'transparent',
        width: '100%',
        '&:hover': {
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : 'primary.main',
          color: '#ffffff'
        },
        '& .MuiButton-startIcon': {
          color: '#ffffff'
        },
        '& .MuiButton-endIcon': {
          color: '#ffffff'
        }
      }}>
          <Box sx={{
          flexGrow: 1,
          pl: 1
        }}>
            {title}
          </Box>
          {info}
        </Button>

        <Collapse in={open} sx={{
        mt: 0.5
      }}>
          {children}
        </Collapse>
      </ListItem>;
  }
  if (title === 'All Contacts' || title === 'Communication Theater' || title === 'Customer Feedback' || title === 'Prospect Management' || title === 'Employee Engagement' || title === 'Monthly Reports' || title === 'Business Intelligence') {
    return <ListItem disableGutters sx={{
      display: 'block',
      mb: 0.5,
      py: 0,
      px: 1
    }}>
        <NextLink href={path as string} passHref>
          <Button startIcon={icon} endIcon={chip} disableRipple sx={{
          fontWeight: 500,
          color: active ? 'neutral.100' : 'neutral.300',
          fontFamily: active ? 'proxima-nova-bold' : 'proxima-nova-regular',
          justifyContent: 'flex-start',
          pl: `${paddingLeft}px`,
          pr: 3,
          textAlign: 'left',
          textTransform: 'none',
          backgroundColor: active || path?.includes('/dashboard/all-contacts/') || path?.includes('/dashboard/business-intelligence') ? 'primary.main' : 'transparent',
          width: '100%',
          '&:hover': {
            backgroundColor: active ? 'rgba(255,255,255, 0.08)' : 'primary.main',
            color: '#ffffff'
          },
          '& .MuiButton-startIcon': {
            color: '#ffffff'
          },
          '& .MuiButton-endIcon': {
            color: '#ffffff'
          }
        }}>
            <Box sx={{
            flexGrow: 1,
            pl: 1
          }}>{title}</Box>
            {info}
          </Button>
        </NextLink>
      </ListItem>;
  }

  // Leaf
  return <ListItem disableGutters sx={{
    display: 'block',
    mb: 0.5,
    py: 0,
    px: 1
  }} data-sentry-element="ListItem" data-sentry-component="DashboardSidebarItem" data-sentry-source-file="dashboard-sidebar-item.tsx">
      <NextLink href={path as string} passHref data-sentry-element="NextLink" data-sentry-source-file="dashboard-sidebar-item.tsx">
        <Button startIcon={icon} endIcon={chip} disableRipple sx={{
        fontWeight: 500,
        fontFamily: active ? 'proxima-nova-bold' : 'proxima-nova-regular',
        borderRadius: 1,
        color: 'neutral.300',
        justifyContent: 'flex-start',
        pl: `${paddingLeft}px`,
        pr: 3,
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        ...(active && {
          color: 'primary.main'
        }),
        '& .MuiButton-startIcon': {
          color: active ? 'primary.main' : '#ffffff'
        },
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        }
      }} data-sentry-element="Button" data-sentry-source-file="dashboard-sidebar-item.tsx">
          <Box sx={{
          flexGrow: 1,
          pl: 1
        }} data-sentry-element="Box" data-sentry-source-file="dashboard-sidebar-item.tsx">{title}</Box>
          {info}
        </Button>
      </NextLink>
    </ListItem>;
};
DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};
DashboardSidebarItem.defaultProps = {
  active: false,
  open: false
};