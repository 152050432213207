import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/lib/state/hooks';
import { usePusher } from '@/lib/hooks/use-pusher';
import {
  addCallToQueue,
  addIvrCurrentCallsCounter,
  setIncomingCallData,
  subtractIvrCurrentCallsCounter,
  updateOutboundCallStatus,
  updateOutboundCallData,
  setWidgetVisibility,
  resetCallData
} from '@/lib/state/slices/call-center-slice';
import {
  resetNotificationBell,
  addMessageNotificationBell,
  removeMessageNotificationBell,
  Message
} from '@/lib/state/slices/notification-bell-slice';
import { UserInterface } from '@/lib/types/user-interface';
import { Notification } from '@/lib/types/notifications';
import { store } from '@/lib/state/store';

/**
 * useNotifications Custom Hook
 *
 * This hook manages real-time notifications for a call center application.
 * It handles various voice call events, manages notification states,
 * and interacts with Redux for global state management.
 *
 * Key features:
 * - Pusher integration for real-time events
 * - Handling of various call center events (call creation, claiming, recording, etc.)
 * - Management of active and claimed calls
 * - Notification state management (adding, removing, marking as read)
 * - Integration with Redux for global state updates
 *
 * Usage:
 * const { handleMarkAllAsRead, handleRemoveOne, newEvents, notifications, unread } = useNotifications();
 */

export type pusherEventData = {
  name: string;
  start_time: string;
  status:
    | 'idle'
    | 'initiating'
    | 'waiting'
    | 'ringing'
    | 'connected'
    | 'disconnected'
    | 'failed'
    | null;
  agent: string;
  phone_number: string;
  id: number;
  sid: string;
  customer_store_id: number;
};

const useNotifications = () => {
  const dispatch = useDispatch();

  // State for managing events and notifications
  const [newEvents, setNewEvents] = useState<Message[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Refs for managing active calls and claimed calls
  // These refs persist across re-renders and don't cause re-renders when updated
  const activeCallIdRef = useRef<number | null>(null);
  const processedIvrCalls = useRef<Set<unknown>>(new Set());

  // Selectors for retrieving state from Redux
  const incomingQueue = useAppSelector(
    (state) => state.callCenter.incomingQueue
  );

  const user = useAppSelector((state) => state.auth.user) as UserInterface;

  // Memoized user channels for Pusher subscription
  // This ensures that channels are only recalculated when the user object changes
  const userChannels = useMemo(() => {
    if (user) {
      return user.should_subscribe_to
        ?.filter(
          (channel) =>
            // Filter out channels that are not relevant to the call center
            channel.name != null || channel.name !== 'private-chat-stream'
        )
        .map((channel: { name: string }) =>
          channel.name.replace('private-', '').replace('public-', '')
        );
    }
    return [];
  }, [user]);

  // Initialize Pusher
  // This gets the Pusher token from the user permissions
  const pusher_token = (user?.permissions?.pusher_token as string) ?? '';
  // usePusher is likely a custom hook that initializes and returns a Pusher instance - DO NOT REMOVE
  const pusher = usePusher(pusher_token);

  // Handler for Voice Call Center Caller in IVR
  // This function is memoized to prevent unnecessary re-creations
  const handleVoiceCallCenterCallerInIvr = useCallback(
    (data: pusherEventData) => {
      console.log('Voice Call Center Caller In Ivr', data);
      if (data.id && !processedIvrCalls.current.has(data.id)) {
        const callExists = incomingQueue.some((call) => call.id === data.id);

        // we are checking if the call is already in the queue using the call id as the unique identifier
        if (!callExists) {
          // console.log('New call in IVR, incrementing counter');
          dispatch(addIvrCurrentCallsCounter());
          processedIvrCalls.current.add(data.id);
        } else {
          // console.log('Call already in queue, not incrementing counter');
        }
      } else {
        // console.log('Call already processed, ignoring');
      }
    },
    [dispatch, incomingQueue]
  );

  // Handler for Voice Call Created event
  const handleVoiceCallCreated = useCallback(
    (data: pusherEventData) => {
      // console.log('Voice Call Created', data);
      const currentState = store.getState().callCenter;

      if (data.sid === currentState.outboundCallSid) {
        // console.log(
        //   'Use Notification Hook - handleVoiceCallCreated - Updating outbound call data'
        // );
        dispatch(updateOutboundCallData(data));
        dispatch(updateOutboundCallStatus(data.status));
      } else if (!currentState.isOutboundCall) {
        // console.log(
        //   'Use Notification Hook - handleVoiceCallCreated - Handling incoming call'
        // );
        const existingCall = currentState.incomingQueue.find(
          (call) => call.id === data.id
        );

        if (!existingCall) {
          dispatch(setIncomingCallData(data));
          dispatch(addCallToQueue(data));
          dispatch(subtractIvrCurrentCallsCounter());
        } else {
          // console.log('Ignoring duplicate notification for incoming call');
        }
      } else {
        // console.log('Ignoring duplicate notification for outbound call');
      }
    },
    [dispatch]
  );

  // Handler for Voice Call Center Call Claimed event
  const handleVoiceCallCenterCallClaimed = useCallback(
    (data: pusherEventData) => {
      console.log('Voice Call Center Call Claimed', data);
      dispatch(subtractIvrCurrentCallsCounter());
      dispatch(removeMessageNotificationBell(data.id));
      activeCallIdRef.current = data.id;
    },
    [dispatch]
  );

  // Handler for Voice Call Recording Message event
  const handleVoiceCallRecordingMessage = useCallback(
    (data: Message) => {
      // console.log('Voice Call Recording Message', data);
      // console.log('activeCallId Ref:', activeCallIdRef.current);
      // console.log('Data ID:', data.id);

      if (activeCallIdRef.current !== data.id) {
        // console.log('Relevant call detected, adding to notification bell');
        dispatch(addMessageNotificationBell(data));
      }
      dispatch(setWidgetVisibility(false));
    },
    [dispatch]
  );

  // Main Pusher event handler
  // This function handles all incoming Pusher events and delegates to specific handlers
  const recentEvents = useRef(new Set());

  const handlePusherEvent = useCallback(
    (e: string, data: pusherEventData | Message) => {
      const eventKey = `${e}-${JSON.stringify(data)}`;
      if (recentEvents.current.has(eventKey)) {
        return; // Ignore duplicate event
      }
      recentEvents.current.add(eventKey);
      setTimeout(() => recentEvents.current.delete(eventKey), 1000); // Remove from set after 1 second

      console.log(
        'Use Notification Hook - handlePusherEvent',
        e,
        'data is:',
        data
      );

      switch (e) {
        // both VoiceCallCreated (channel Store.218) and VoiceCallCenterCallerWaiting (channel: VoiceCallCenter.166) are handled by handleVoiceCallCreated
        case 'VoiceCallCreated':
        case 'VoiceCallCenterCallerWaiting':
          if ('status' in data) {
            handleVoiceCallCreated(data as pusherEventData);
          }
          break;
        case 'VoiceCallCompleted':
          // TODO - the object is not a pusherEventData, so we need to handle it differently
          if ('voice_call_id' in data) {
            dispatch(subtractIvrCurrentCallsCounter());
            dispatch(resetCallData());
          }
          break;
        case 'VoiceCallCenterCallClaimed':
          if ('id' in data) {
            handleVoiceCallCenterCallClaimed(data as pusherEventData);
          }
          break;

        case 'VoiceCallRecordingMessage':
          handleVoiceCallRecordingMessage(data as Message);
          break;
        case 'VoiceCallCenterCallerInIvr':
          if ('id' in data) {
            handleVoiceCallCenterCallerInIvr(data as pusherEventData);
          }
          break;
        case 'VoiceCallCenterClaimed':
          if ('id' in data) {
            dispatch(addCallToQueue(data as pusherEventData));
          }
          break;
        case 'VoiceCallCenterCallerLeft':
          //this is event will
          dispatch(subtractIvrCurrentCallsCounter());
          break;
        // case 'AssignmentCreated':
        // this object data is not a pusherEventData, so we need to handle it differently
        //{
        //     "user_id": 338,
        //     "customer_store_id": 27116,
        //     "title": "New Assignment",
        //     "body": "Goran Cvetic assigned Goran Cvetic to you",
        //     "active_assignments": [],
        //     "sent_at": "2024-10-16 21:59:24"
        // }
        // if (data) {
        //   dispatch(addMessageNotificationBell(data));
        // }
        // break;
        default:
        // console.log('UNHANDLED pusher event', e, 'data is:', data);
      }
    },
    [
      dispatch,
      handleVoiceCallCreated,
      handleVoiceCallCenterCallClaimed,
      handleVoiceCallRecordingMessage,
      handleVoiceCallCenterCallerInIvr
    ]
  );

  // Set up Pusher listeners
  // This effect runs when userChannels or handlePusherEvent changes
  useEffect(() => {
    if (
      window.Echo !== undefined &&
      window.Echo !== null &&
      userChannels!.length > 0
    ) {
      userChannels!.forEach((channelName) => {
        // console.log(`Setting up Pusher listener for channel: ${channelName}`);
        window.Echo?.private(channelName).listenToAll(handlePusherEvent);
      });
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      processedIvrCalls.current.clear();
      // Clean up Pusher listeners here if necessary
    };
  }, [userChannels, handlePusherEvent]);

  // Calculate unread notifications count
  // This is memoized to prevent unnecessary recalculations
  const unread = useMemo((): number => {
    return notifications.reduce(
      (acc, notification) => acc + (notification.read ? 0 : 1),
      0
    );
  }, [notifications]);

  // Handler for removing a single notification
  const handleRemoveOne = useCallback((notificationId: string): void => {
    setNotifications((prevState) =>
      prevState.filter((notification) => notification.id !== notificationId)
    );
  }, []);

  // Handler for marking all notifications as read
  const handleMarkAllAsRead = useCallback((): void => {
    dispatch(resetNotificationBell());
    setNotifications((prevState) =>
      prevState.map((notification) => ({ ...notification, read: true }))
    );
  }, [dispatch]);

  // Return the public interface of the hook
  return {
    handleMarkAllAsRead,
    handleRemoveOne,
    newEvents,
    notifications,
    unread
  };
};

export default useNotifications;
