import { ReactNode, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import NextLink from 'next/link';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Drawer, useMediaQuery, Theme } from '@mui/material';
import { Logo } from '@/assets/logos/logo';
import { Scrollbar } from '../../scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { useAuth } from '@/lib/hooks/use-auth';
import toast from 'react-hot-toast';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import CircularWithValueLabel from '@/components/_shared/circle-progress-with-label';
import { useGetSettingsOptimizeAccountQuery } from '@/lib/state/services/settings-optimize-account-api';
import { useRouter } from 'next/router';
interface DashboardSidebarProps {
  onClose?: () => void;
  open?: boolean;
}
interface Children {
  title: string;
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  is_allowed?: boolean;
}
interface Item {
  title: string;
  children?: Children[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  is_allowed?: boolean;
}
interface Section {
  title: string;
  items: Item[];
}
export const DashboardSidebar: FC<React.PropsWithChildren<DashboardSidebarProps>> = props => {
  const user = useAuth()?.user;
  const getSections = (t: TFunction): Section[] => {
    const items = [{
      title: t('Business Intelligence'),
      path: '/dashboard/business-intelligence/',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M4.25 2A2.25 2.25 0 002 4.25v11.5A2.25 2.25 0 004.25 18h11.5A2.25 2.25 0 0018 15.75V4.25A2.25 2.25 0 0015.75 2H4.25zM15 5.75a.75.75 0 00-1.5 0v8.5a.75.75 0 001.5 0v-8.5zm-8.5 6a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5zM8.584 9a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75zm3.58-1.25a.75.75 0 00-1.5 0v6.5a.75.75 0 001.5 0v-6.5z" clipRule="evenodd" />
          </svg>,
      children: [{
        title: 'Business Summary',
        path: '/dashboard/business-intelligence/intelligence-overview',
        is_allowed: user?.permissions?.can_access_performance
      }, {
        title: 'AI Recommendations',
        path: '/dashboard/business-intelligence/ai-recommendations',
        is_allowed: user?.permissions?.can_access_performance
      },
      // {
      //   title: 'Marketing Appraisal',
      //   path: '/dashboard/business-intelligence/marketing-appraisal',
      //   is_allowed: user?.permissions?.can_access_performance
      // },
      {
        title: 'Customer Behaviors',
        path: '/dashboard/business-intelligence/customer-behaviors',
        is_allowed: user?.permissions?.can_access_performance
      }, {
        title: 'Product Performance',
        path: '/dashboard/business-intelligence/product-performance',
        is_allowed: true
      }, {
        title: 'Shopping Trends',
        path: '/dashboard/business-intelligence/shopping-trends',
        is_allowed: user?.permissions?.can_access_performance
      }, {
        title: 'Multi-Operation Analysis',
        path: '/dashboard/business-intelligence/multi-operation-analysis',
        is_allowed: user?.permissions?.can_access_locations
      }, {
        title: 'Communication Volume',
        path: '/dashboard/business-intelligence/communication-volume',
        is_allowed: true
      }]
    }, {
      title: t('Communication Theater'),
      path: '/dashboard/communication-center',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path d="M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z" />
            <path d="M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z" />
          </svg>
    }, {
      title: t('Enhanced Marketing'),
      path: '/dashboard/enhanced-marketing',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path d="M13.92 3.845a19.361 19.361 0 01-6.3 1.98C6.765 5.942 5.89 6 5 6a4 4 0 00-.504 7.969 15.974 15.974 0 001.271 3.341c.397.77 1.342 1 2.05.59l.867-.5c.726-.42.94-1.321.588-2.021-.166-.33-.315-.666-.448-1.004 1.8.358 3.511.964 5.096 1.78A17.964 17.964 0 0015 10c0-2.161-.381-4.234-1.08-6.155zM15.243 3.097A19.456 19.456 0 0116.5 10c0 2.431-.445 4.758-1.257 6.904l-.03.077a.75.75 0 001.401.537 20.902 20.902 0 001.312-5.745 1.999 1.999 0 000-3.545 20.902 20.902 0 00-1.312-5.745.75.75 0 00-1.4.537l.029.077z" />
          </svg>,
      children: [
      //commented out but still accesible through hard url path
      // {
      //   title: t('Intelligence Summary'),
      //   path: '/dashboard/enhanced-marketing/intelligence-summary',
      //   is_allowed: user?.permissions?.can_access_marketing_groups
      // },
      {
        title: t('Enhanced Groups'),
        path: '/dashboard/enhanced-marketing/groups',
        is_allowed: user?.permissions?.can_access_marketing_groups
      }, {
        title: t('Enhanced Campaigns'),
        path: '/dashboard/enhanced-marketing/campaigns',
        is_allowed: user?.permissions?.can_access_campaigns
      }, {
        title: t('Enhanced Landing Pages'),
        path: '/dashboard/enhanced-marketing/landing-pages',
        is_allowed: true
      }, {
        title: t('Deals & Narrative'),
        path: '/dashboard/enhanced-marketing/deals-narrative',
        is_allowed: true
      }]
    }, {
      title: t('Customer Feedback'),
      path: '/dashboard/customer-feedback',
      is_allowed: user?.permissions?.can_access_feedback,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
          </svg>
    }, {
      title: t('Prospect Management'),
      path: '/dashboard/prospect-management',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path d="M7.27691 5.78883C7.44719 5.68047 7.65753 5.58362 7.9002 5.50866L7.9002 7.29154C7.65753 7.21658 7.44719 7.11973 7.27691 7.01137C6.87639 6.75649 6.8002 6.51969 6.8002 6.4001C6.8002 6.2805 6.87639 6.0437 7.27691 5.78883Z" fill="#F8F9FA" />
            <path d="M10.1002 11.4915L10.1002 9.70866C10.3429 9.78362 10.5532 9.88047 10.7235 9.98883C11.124 10.2437 11.2002 10.4805 11.2002 10.6001C11.2002 10.7197 11.124 10.9565 10.7235 11.2114C10.5532 11.3197 10.3429 11.4166 10.1002 11.4915Z" fill="#F8F9FA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.0002 16.9001C13.8603 16.9001 17.8002 13.1393 17.8002 8.5001C17.8002 3.86091 13.8603 0.100098 9.0002 0.100098C4.14009 0.100098 0.200195 3.86091 0.200195 8.5001C0.200195 13.1393 4.14009 16.9001 9.0002 16.9001ZM10.1002 3.2501C10.1002 2.6702 9.60771 2.2001 9.0002 2.2001C8.39268 2.2001 7.9002 2.6702 7.9002 3.2501V3.34669C7.21644 3.46926 6.58402 3.70587 6.05657 4.04152C5.26273 4.54669 4.6002 5.3599 4.6002 6.4001C4.6002 7.4403 5.26273 8.2535 6.05657 8.75867C6.58402 9.09433 7.21645 9.33094 7.90021 9.45351L7.90022 11.4918C7.47011 11.3585 7.15128 11.1586 6.97253 10.962C6.57438 10.524 5.87964 10.477 5.42078 10.857C4.96193 11.2371 4.91272 11.9002 5.31087 12.3382C5.92961 13.0189 6.86571 13.4675 7.9002 13.6531L7.9002 13.7501C7.90018 14.33 8.39266 14.8001 9.00017 14.8001C9.60769 14.8001 10.1002 14.33 10.1002 13.7501L10.1002 13.6535C10.784 13.5309 11.4164 13.2943 11.9438 12.9587C12.7377 12.4535 13.4002 11.6403 13.4002 10.6001C13.4002 9.55989 12.7377 8.74669 11.9438 8.24152C11.4164 7.90587 10.784 7.66926 10.1002 7.54669L10.1002 5.50839C10.5303 5.64169 10.8491 5.8416 11.0279 6.03824C11.426 6.47624 12.1208 6.52322 12.5796 6.14316C13.0385 5.76311 13.0877 5.09995 12.6895 4.66195C12.0708 3.98128 11.1347 3.53271 10.1002 3.34707V3.2501Z" fill="#F8F9FA" />
          </svg>
    }, {
      title: t('All Contacts'),
      path: '/dashboard/all-contacts',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-11-4.69v.447a3.5 3.5 0 001.025 2.475L8.293 10 8 10.293a1 1 0 000 1.414l1.06 1.06a1.5 1.5 0 01.44 1.061v.363a1 1 0 00.553.894l.276.139a1 1 0 001.342-.448l1.454-2.908a1.5 1.5 0 00-.281-1.731l-.772-.772a1 1 0 00-1.023-.242l-.384.128a.5.5 0 01-.606-.25l-.296-.592a.481.481 0 01.646-.646l.262.131a1 1 0 00.447.106h.188a1 1 0 00.949-1.316l-.068-.204a.5.5 0 01.149-.538l1.44-1.234A6.492 6.492 0 0116.5 10z" clipRule="evenodd" />
          </svg>
    }, {
      title: t('Employee Engagement'),
      path: '/dashboard/employee-engagement',
      is_allowed: false,
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M8 7a5 5 0 113.61 4.804l-1.903 1.903A1 1 0 019 14H8v1a1 1 0 01-1 1H6v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-2a1 1 0 01.293-.707L8.196 8.39A5.002 5.002 0 018 7zm5-3a.75.75 0 000 1.5A1.5 1.5 0 0114.5 7 .75.75 0 0016 7a3 3 0 00-3-3z" clipRule="evenodd" />
          </svg>
    }, {
      title: t('Calendar Activity'),
      path: '/dashboard/calendar-activity',
      is_allowed: true,
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M7.7002 3.1499C7.09268 3.1499 6.6002 3.62 6.6002 4.1999C6.6002 4.7798 7.09268 5.2499 7.7002 5.2499H14.3002C14.9077 5.2499 15.4002 4.7798 15.4002 4.1999C15.4002 3.62 14.9077 3.1499 14.3002 3.1499H7.7002Z" fill="white" />
            <path d="M4.4002 7.3499C4.4002 6.77 4.89268 6.2999 5.5002 6.2999H16.5002C17.1077 6.2999 17.6002 6.77 17.6002 7.3499C17.6002 7.9298 17.1077 8.3999 16.5002 8.3999H5.5002C4.89268 8.3999 4.4002 7.9298 4.4002 7.3499Z" fill="white" />
            <path d="M2.2002 11.5499C2.2002 10.3901 3.18517 9.4499 4.4002 9.4499H17.6002C18.8152 9.4499 19.8002 10.3901 19.8002 11.5499V15.7499C19.8002 16.9097 18.8152 17.8499 17.6002 17.8499H4.4002C3.18517 17.8499 2.2002 16.9097 2.2002 15.7499V11.5499Z" fill="white" />
          </svg>
    }
    // {
    //   title: t('Monthly Reports'),
    //   path: '/dashboard/reports',
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 20 20"
    //       fill="currentColor"
    //       className="w-5 h-5"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zm10.857 5.691a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 00-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   )
    // }
    ];
    const filteredItems = items?.filter(item => item?.is_allowed)?.map(item => ({
      ...item,
      children: item?.children?.filter(child => child.is_allowed)
    }));
    return [{
      title: t(''),
      items: filteredItems
    }];
  };
  const {
    onClose,
    open
  } = props;
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const sections = useMemo(() => getSections(t), [t]);
  const {
    logout
  } = useAuth();
  const dispatchRedux = useDispatch();
  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }
    if (open) {
      onClose?.();
    }
  };
  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      router.push('/authentication/login').catch(console.error);
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };
  useEffect(handlePathChange,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [router.isReady, router.asPath]);
  const store_id = useAuth()?.user?.store_id;
  const {
    data: storeData
  } = useGetSettingsOptimizeAccountQuery(store_id);
  const content = <>
      <Scrollbar sx={{
      height: '100%',
      '& .simplebar-content': {
        height: '100%'
      }
    }}>
        <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
          <div>
            <Box sx={{
            p: 3
          }}>
              <Logo variant="light" />
            </Box>
          </div>

          <Box sx={{
          flexGrow: 1,
          mt: 0
        }}>
            {sections.map(section => <DashboardSidebarSection key={section.title} path={router.asPath} {...section} />)}
          </Box>

          <Box sx={{
          p: 2
        }}>
            <List>
              <NextLink href="/dashboard/optimize-account" passHref>
                <ListItem disablePadding>
                  <ListItemButton sx={{
                  borderRadius: 1,
                  color: 'neutral.300',
                  justifyContent: 'flex-start',
                  pr: 0,
                  textAlign: 'left',
                  textTransform: 'none',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.08)'
                  }
                }}>
                    <ListItemIcon>
                      {storeData && <CircularWithValueLabel storeData={storeData} />}
                    </ListItemIcon>
                    <ListItemText primary="Optimize Account" />
                  </ListItemButton>
                </ListItem>
              </NextLink>

              {/* <UserbackSidebarSuggestion
                onClose={() => onClose?.() || undefined}
               /> */}
              <ListItem disablePadding onClick={handleLogout}>
                <ListItemButton sx={{
                borderRadius: 1,
                color: 'neutral.300',
                justifyContent: 'flex-start',
                pr: 0,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.08)'
                }
              }}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
              <div className="mt-6 text-xs text-zinc-400">
                &copy; {new Date().getFullYear()}. All rights reserved.
                <NextLink href="https://ripemetrics.com/legal/terms-of-service" passHref className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline" target="_blank">
                  Terms of Service
                </NextLink>
              </div>
            </List>
          </Box>
        </Box>
      </Scrollbar>
    </>;
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  if (xlUp) {
    return <Drawer anchor="left" open PaperProps={{
      sx: {
        backgroundColor: 'neutral.900',
        borderRightColor: 'divider',
        borderRightStyle: 'solid',
        borderRightWidth: theme => theme.palette.mode === 'dark' ? 1 : 0,
        color: '#FFFFFF',
        width: 280,
        zIndex: theme => theme.zIndex.appBar + 100
      }
    }} variant="permanent">
        {content}
      </Drawer>;
  }
  return <Drawer anchor="left" onClose={onClose} open={open} PaperProps={{
    sx: {
      backgroundColor: 'neutral.900',
      color: '#FFFFFF',
      width: 280
    }
  }} sx={{
    zIndex: theme => theme.zIndex.appBar + 100
  }} variant="temporary" data-sentry-element="Drawer" data-sentry-component="DashboardSidebar" data-sentry-source-file="dashboard-sidebar.tsx">
      {content}
    </Drawer>;
};
DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};