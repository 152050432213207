import {
  BuyStoreNumberRequest,
  BuyStoreNumberResponse,
  MarketingSourceRequest,
  MarketingSourcesData,
  TwilioGetNumberRequest,
  TwilioGetPhoneNumbersResponse
} from './../../types/settings';
import { api } from './api';

import {
  Store,
  StoreSettingsFormValueTypes,
  MarketingSourceMutationRequest
} from '@/lib/types/settings';
import { UserInterface } from '@/lib/types/user-interface';
import { PdfItemUploadResponse } from '@/components/dashboard/settings/location/ai-setup/document-uploader/settings-ai-training-express-training-form';

/**
 * API for managing customer settings i.e. THE STORE.
 */
export const customerSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get store settings.
     */
    getStoreSettings: build.query<{ data?: Store }, void>({
      query: () => `v2/stores`,
      providesTags: ['StoreData']
    }),

    /**
     * Update store settings.
     *
     * @param body - Form values for updating store settings.
     */
    updateStoreSettings: build.mutation<
      UpdateStoreSettings,
      StoreSettingsFormValueTypes
    >({
      query: (body) => ({
        url: `v2/store/update`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['StoreData', 'EnhancedCampaignsAutomatedCampaignsData']
    }),

    /**
     * Get store alerts.
     */
    getStoreAlerts: build.query<StoreAlertsResponse, void>({
      query: () => `v2/store_alerts`,
      providesTags: ['StoreAlerts']
    }),

    /**
     * Update store alerts.
     *
     * @param body - Request body for updating store alerts.
     */
    updateStoreAlerts: build.mutation<
      StoreAlertsResponse,
      UpdateStoreAlertsRequest
    >({
      query: (body) => ({
        url: `v2/store_alerts/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['StoreAlerts']
    }),

    /**
     * Get marketing source data.
     */
    getMarketingSourceData: build.query<MarketingSourcesData[], void>({
      query: () => `v2/marketing_source_stores/toggle_template`,
      providesTags: ['StoreData']
    }),

    /**
     * Update marketing source data.
     *
     * @param body - Request body for updating marketing source data.
     */
    updateMarketingSourceData: build.mutation<
      MarketingSourceRequest,
      MarketingSourceMutationRequest
    >({
      query: (body) => ({
        url: `v2/marketing_source_store/update`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['StoreData']
    }),

    /**
     * Add a team member.
     *
     * @param body - User intferface details for adding a team member.
     */
    addTeamMember: build.mutation<unknown, UserInterface>({
      query: (body) => ({
        url: `v2/store/create_new_user`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['StoreUsers']
    }),
    /**
     * Upload industry knowledge PDF.
     *
     * @param data - PDF details for uploading.
     */
    uploadIndustryKnowledgePDF: build.mutation<
      any,
      { pdf_uuid: string; namespace: string; index: number }
    >({
      query: (data) => {
        const { pdf_uuid, namespace, index } = data;
        return {
          url: `/v2/pinecone_api/upsert_pdf/${index}/${namespace}`,
          method: 'POST',
          body: {
            pdf_uuid
          }
        };
      },
      invalidatesTags: ['StoreData']
    }),

    /**
     * Upsert PDF to Pinecone.
     *
     * @param file - PDF file for upserting to Pinecone.
     */
    upsertPdfToPinecone: build.mutation<PdfItemUploadResponse, { pdf: File }>({
      query: (file) => {
        const formData = new FormData();
        formData.append('pdf', file.pdf);

        return {
          url: '/v2/pinecone_api/upsert_pdf_pinecone',
          method: 'POST',
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          body: formData
        };
      },
      invalidatesTags: ['StoreData']
    }),
    deletePdf: build.mutation<any, any>({
      query: (data) => {
        return {
          url: '/v2/pinecone_api/delete_document',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['StoreData']
    }),
    getTwilioPhoneNumbers: build.mutation<
      TwilioGetPhoneNumbersResponse,
      TwilioGetNumberRequest
    >({
      query: (body) => {
        return {
          url: `/v2/store/twilio/get_phone_numbers`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['StoreData']
    }),
    buyTwilioPhoneNumber: build.mutation<
      BuyStoreNumberResponse,
      BuyStoreNumberRequest
    >({
      query: (body) => {
        return {
          url: `/v2/store/twilio/buy_phone_number`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['StoreData']
    })
  })
});

// Export individual hooks for easy usage.
export const {
  useGetStoreSettingsQuery,
  useUpdateStoreSettingsMutation,
  useGetMarketingSourceDataQuery,
  useUpdateMarketingSourceDataMutation,
  useAddTeamMemberMutation,
  useUploadIndustryKnowledgePDFMutation,
  useUpsertPdfToPineconeMutation,
  useGetTwilioPhoneNumbersMutation,
  useBuyTwilioPhoneNumberMutation,
  useDeletePdfMutation,
  useGetStoreAlertsQuery,
  useUpdateStoreAlertsMutation
} = customerSettingsApi;

/**
 * Interface for updating store settings.
 */
export interface UpdateStoreSettings {
  data?: Store;
  error?: {
    data: {
      message: string;
      errors: string[];
    };
  };
}
export interface CreateStoreResponse {
  data?: {
    data?: Store;
  };
  error?: {
    data: {
      message: string;
      errors: string[];
    };
  };
}

/**
 * Interface for updating store alerts.
 *  */

interface StoreAlertsResponse {
  missed_call_email_list: number[];
  missed_call_sms_list: number[];
  missed_call_slack_list: string[];
  negative_feedback_email_list: number[];
  negative_feedback_sms_list: number[];
  negative_feedback_slack_list: string[];
  monthly_newsletter_reminder_email_list: number[];
  monthly_newsletter_reminder_sms_list: number[];
  monthly_newsletter_reminder_slack_list: string[];
  unhandled_ivr_email_list: number[];
  unhandled_ivr_sms_list: number[];
  unhandled_ivr_slack_list: string[];
  unhandled_chat_email_list: number[];
  unhandled_chat_sms_list: number[];
  unhandled_chat_slack_list: string[];
}

interface UpdateStoreAlertsRequest {
  missed_call_email_list?: number[];
  missed_call_sms_list?: number[];
  missed_call_slack_list?: string[];
  negative_feedback_email_list?: number[];
  negative_feedback_sms_list?: number[];
  negative_feedback_slack_list?: string[];
  monthly_newsletter_reminder_email_list?: number[];
  monthly_newsletter_reminder_sms_list?: number[];
  monthly_newsletter_reminder_slack_list?: string[];
  unhandled_ivr_email_list?: number[];
  unhandled_ivr_sms_list?: number[];
  unhandled_ivr_slack_list?: string[];
  unhandled_chatbot_email_list?: number[];
  unhandled_chatbot_sms_list?: number[];
  unhandled_chatbot_slack_list?: string[];
}
